import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/layout/index.vue'
// import Home from '../views/Home.vue'
import Equipment from '@/views/equipment-overview/index.vue'
import List from '@/views/equipment-list/index.vue'
import Alarm from '@/views/alarm-statistics/index.vue'
import Group from '@/views/group-management/index.vue'
import Excel from '@/views/data-export/index.vue'
import Login from '@/views/login/index.vue'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'home',
  component: Layout,
  redirect: '/equipment-overview',
  children: [{
    path: '/equipment-overview',
    component: Equipment
  }, {
    path: '/equipment-list',
    component: List
  }, {
    path: '/alarm-statistics',
    component: Alarm
  }, {
    path: '/group-management',
    component: Group
  }, {
    path: '/data-export',
    component: Excel
  }, {
    name: 'detail',
    path: '/equipment-detail/:index',
    component: () => import('@/views/equipment-detail/index.vue')
  }, {
    name: 'setting',
    path: '/system-setting',
    component: () => import('@/views/system-setting/index.vue')
  }]
}, {
  path: '/login',
  name: 'login',
  component: Login
}]

const router = new VueRouter({
  routes
})

// 挂载路由守卫
import VueCookie from "vue-cookies";

router.beforeEach((to, from, next) => {
  //   // to 将要访问的路径  from 代表从哪个路径来 next 是一个函数表示放行
  //   // next() 放行    next('/login') 强制跳转 /login
  const token = VueCookie.get('userid')
  // console.log(token);
  if (to.path == '/login') {
    if (token) return next('/')
    next()
  }
  if (!token) {
    console.log('/login');
    return next('/login')
  }
  // if (token) {
  //   console.log('/home');
  //   return next('/equipment-overview')
  // }
  // console.log('next');
  next()
})

export default router