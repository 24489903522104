<template>
  <div class="alarm">
    <!-- 告警统计 -->
    <div class="fullh" style="background-color: #fff">
      <el-row
        type="flex"
        :gutter="20"
        justify="space-around"
        class="fullh"
        align="middle"
      >
        <el-col :span="24" class="fullh bg-purple">
          <el-row
            type="flex"
            :gutter="20"
            class="row-bg fullh"
            justify="space-around"
          >
            <el-col :span="24" class="fullh">
              <el-table
                :data="
                  AlarmListData.slice(
                    (currentPage - 1) * pagesize,
                    currentPage * pagesize
                  ) //分页
                    .filter(
                      (data) =>
                        !tableFilter ||
                        data.Devname.toLowerCase().includes(
                          tableFilter.toLowerCase()
                        )
                    )
                "
                border
                row-class-name="warning-row"
                id="AlarmList"
                v-loading="loading"
              >
                <el-table-column
                  label="序号"
                  type="index"
                  width="50"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="Devname"
                  label="设备名称"
                  width=""
                  sortable
                >
                </el-table-column>
                <el-table-column
                  prop="Sub_name"
                  label="子设备名称"
                  width=""
                  sortable
                >
                </el-table-column>
                <el-table-column
                  prop="AlarmTime"
                  label="告警时间"
                  width=""
                  sortable
                >
                </el-table-column>
                <el-table-column
                  prop="AlarmDesc"
                  label="描述"
                  width="200"
                  sortable
                >
                </el-table-column>
                <el-table-column width="550">
                  <template slot="header" slot-scope="scope">
                    <el-input
                      class="table-input"
                      :key="scope.$index"
                      v-model="tableFilter"
                      size="mini"
                      placeholder="输入关键字搜索设备"
                    ></el-input>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.AlarmSuggest }}
                  </template>
                </el-table-column>
              </el-table>
              <el-row
                ><el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[
                    30,
                    50,
                    100,
                    AlarmListData.filter(
                      (data) =>
                        !tableFilter ||
                        data.Devname.toLowerCase().includes(
                          tableFilter.toLowerCase()
                        )
                    ).length,
                  ]"
                  :page-size="pagesize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="
                    AlarmListData.filter(
                      (data) =>
                        !tableFilter ||
                        data.Devname.toLowerCase().includes(
                          tableFilter.toLowerCase()
                        )
                    ).length
                  "
                >
                </el-pagination
              ></el-row>

              <el-row type="flex" :gutter="20" justify="space-around">
                <el-col :span="4">
                  <el-button
                    @click="GetAlarmList"
                    type="primary"
                    size="small"
                    icon="el-icon-refresh"
                    >刷新
                  </el-button>
                  <el-button
                    @click="exportAlarmListExcel()"
                    type="primary"
                    size="small"
                    icon="el-icon-refresh"
                    >导出
                  </el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getAlarmList } from "@/api/require";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      AlarmListData: [], //数据
      timerID: 0, //定时器
      loading: false, //table加载
      tableFilter: "", //table过滤

      currentPage: 1, //  el-pagination 初始页
      pagesize: 30, //  el-pagination 每页的数据
    };
  },
  created() {
    this.getAlarmListData();
  },
  methods: {
    async getAlarmListData() {
      const res = await getAlarmList();
      console.log(res);
      this.AlarmListData = res.data.AlarmList;
      this.$message({
        message: "告警信息已刷新",
        type: "success",
      });
      // this.loading = false;

      this.timer();
    },
    //定时器
    timer() {
      clearInterval(this.timerID);
      this.timerID = setInterval(async () => {
        const res = await getAlarmList();
        this.AlarmListData = res.data.AlarmList;
      }, 5000);
    },
    //刷新
    GetAlarmList() {
      clearInterval(this.timerID);
      this.getAlarmListData();
    },
    //定义导出Excel表格事件
    exportAlarmListExcel() {
      /* 从表生成工作簿对象 */
      var wb = XLSX.utils.table_to_book(document.querySelector("#AlarmList"));
      /* 获取二进制字符串作为输出 */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      var timestamp = new Date().format("yyyy年MM月dd日hh小时mm分ss秒");
      try {
        saveAs(
          //Blob 对象表示一个不可变、原始数据的类文件对象。
          //Blob 表示的不一定是JavaScript原生格式的数据。
          //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
          //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
          new Blob([wbout], {
            type: "application/octet-stream",
          }),
          //设置导出文件名称
          "实时告警记录" + timestamp + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    // size-change	pageSize 改变时会触发	每页条数size
    // current-change	currentPage 改变时会触发	当前页currentPage
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize); // 每页下拉显示数据
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage); // 点击第几页
    },
  },
  beforeDestroy() {
    clearInterval(this.timerID);
    this.timerID = null;
  },
};
</script>

<style lang="scss" scoped>
.alarm {
  height: 100%;
  width: 100%;
  .fullh {
    height: 100%;
  }
  .el-input__inner {
    width: 30% !important;
  }
}
</style>