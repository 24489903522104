<template>
  <div class="login">
    <div
      :class="['background', 'bg1']"
      ref="background"
      v-bind:style="styleObject"
    >
      <div class="load_img" v-show="!loading">
        <!-- 加载动画 -->
        <img class="jzxz2" src="@/assets/images/jzxz2.png" />
        <img class="jzxz1" src="@/assets/images/jzxz1.png" />
      </div>
      <div class="login_box" v-show="loading">
        <div class="logo">
          <img
            v-if="$developmentProject"
            src="@/assets/images/V.svg"
            style="height: 180px"
          />
          <img
            v-else
            src="@/assets/images/logo.png"
            style="height: 50px; width: 175px"
          />
          <h5 class="loadWrapper">—— Powerman Cloud ——</h5>
        </div>
        <div class="hr-line-div"></div>
        <div class="wxlogin">
          <!-- wxde859bee6c95c53a  广东浩源-->
          <!-- wx99af622b90164c0b  -->

          <wxlogin
            v-show="loginMode"
            :appid="id"
            scope="snsapi_login"
            theme="white"
            :redirect_uri="url"
            state="STATE_13245"
            href="data:text/css;base64,aWZyYW1lLGh0bWwsYm9keXtoZWlnaHQ6IGF1dG87fQouaW1wb3dlckJveCAucXJjb2RlIHt3aWR0aDogMjAwcHg7fQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDIxMHB4O30KLmltcG93ZXJCb3ggLmljb24zOF9tc2cuc3VjYyB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLmljb24zOF9tc2cud2FybiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30="
          >
          </wxlogin>
          <div class="login_scan_footer">
            <a
              href="javascript:void(0);"
              class="switch-login"
              @click="loginMode = true"
              >扫码登录</a
            >
            <a
              href="javascript:void(0);"
              class="switch-login"
              @click="loginMode = false"
              >密码登录</a
            >
          </div>

          <div class="userLogin" v-show="!loginMode">
            <el-form
              style="margin: 0 auto"
              :model="loginForm"
              label-position="left"
              label-width="60px"
              ref="loginRef"
              :rules="loginRules"
            >
              <el-form-item label="用户名">
                <el-input
                  v-model="loginForm.user"
                  auto-complete="off"
                  placeholder="请输入用户名"
                  size="small"
                  prefix-icon="el-icon-s-custom"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="pass">
                <el-input
                  v-model="loginForm.pass"
                  auto-complete="off"
                  placeholder="请输入密码"
                  size="small"
                  show-password
                  prefix-icon="el-icon-lock"
                >
                </el-input>
              </el-form-item>
              <el-form-item label-width="60px">
                <el-button
                  type="primary"
                  autofocus
                  style="padding: 8px 30px; float: right"
                  @click="userLogin"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import wxlogin from "vue-wxlogin";
import { login } from "../../api/require";

export default {
  components: {
    wxlogin,
  },
  data() {
    return {
      loading: false,
      styleObject: "",
      loginMode: true, //f
      loginForm: {
        user: "",
        pass: "",
      },
      loginRules: { pass: [{ required: false, message: "年龄不能为空" }] },
      acitveName: "first",
      // <!-- wxde859bee6c95c53a  广东浩源-->
      //     <!-- wx99af622b90164c0b  -->
      id: this.$developmentProject
        ? "wx99af622b90164c0b"
        : "wxde859bee6c95c53a",
      url: this.$developmentProject
        ? "http://cloud.vnata.cn/loin.php"
        : "http://cloud.hooypower.com/loin.php",
    };
  },
  mounted() {
    console.log(this.$developmentProject);
    setTimeout(() => {
      this.loading = true;
    }, 2000);
    this.reCss();
  },
  methods: {
    switchLogin() {
      this.loginMode = !this.loginMode;
    },
    async userLogin() {
      const md5 = require("js-md5"); //md5加密
      const res = await login(this.loginForm.user, md5(this.loginForm.pass));
      if (res.data.Code == 0) {
        this.$router.go(0);
      } else {
        console.log(this.$refs["loginRef"]);
        this.$refs["loginRef"].fields[0].validateState = "error";
        this.$refs["loginRef"].fields[0].validateMessage =
          "你填写的帐号或密码不正确";
      }
      console.log(res);
    },
    reCss() {
      const title = document.getElementsByClassName("title");
      console.log(title);
    },
  },
};
</script>

<style lang="scss" scoped>
.load_img {
  width: 365px;
  height: 365px;

  .jzxz2 {
    position: absolute;
    animation: xz2 7s infinite linear;
  }
  @keyframes xz2 {
    from {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(-180deg);
    }

    to {
      transform: rotate(-360deg);
    }
  }
  .jzxz1 {
    position: absolute;
    animation: xz1 8s infinite linear;
  }

  @keyframes xz1 {
    from {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(180deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.login {
  height: 100%;
  width: 100%;
  // background-color: #87878e;
  .background {
    height: 100%;
    width: 100%;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    .login_box {
      height: 400px;
      min-width: 700px;
      position: relative;
      border-radius: 10px;
      box-shadow: rgba(5, 15, 53, 0.58) 9px 16px 16px 0px;
      background-color: #0000005c;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      .logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 300px;
        h5 {
          text-align: center;
          color: white;
        }
        .switch-login {
          position: relative;
          left: 220px;
          top: 20px;
          text-decoration: none;
          color: #666;
          font-size: 12px;
        }
      }
      .hr-line-div {
        width: 3px;
        height: 100%;
        background: radial-gradient(#9eccec 25%, transparent 74%);
      }
      .wxlogin {
        width: 300px;
        margin-top: 40px;
        // height: 100%;
      }
      .userLogin {
        width: 300px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
      }
    }
  }
  .bg1 {
    background: linear-gradient(
      45deg,
      rgba(75, 192, 200, 0.5),
      rgba(254, 172, 94, 0.5),
      rgba(199, 121, 208, 0.5),
      rgba(75, 192, 200, 0.5)
    );
  }
  .bg2 {
    background: linear-gradient(
      180deg,
      rgba(75, 192, 200, 0.5),
      rgba(254, 172, 94, 0.5),
      rgba(199, 121, 208, 0.5),
      rgba(75, 192, 200, 0.5)
    );
  }
  .bg3 {
    background: linear-gradient(
      90deg,
      rgba(75, 192, 200, 0.5),
      rgba(254, 172, 94, 0.5),
      rgba(199, 121, 208, 0.5),
      rgba(75, 192, 200, 0.5)
    );
  }
}

.login_scan_footer {
  padding: 14px 0;
  border-top: solid 1px #ebeff2;
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  width: 300px;
  box-sizing: border-box;
  a {
    font-size: 14px;
    color: #ffff;
    text-align: center;
    display: inline-block;
    width: 50%;
    text-decoration: none;
  }
}
// html,body{height: auto;}
// .impowerBox .qrcode {width: 200px;}
// .impowerBox .title {display: none;}
// .impowerBox .info {width: 210px;}
// .impowerBox .icon38_msg.succ {display: none}
// .impowerBox .icon38_msg.warn {display: none}
// .impowerBox .status {text-align: center;}
</style>