<template>
  <div class="table">
    <div class="top">
      <div style="width: 50%">
        <el-input
          placeholder="请输入搜索设备名称"
          v-model="searchName"
          class="input-with-select"
        >
          <el-select
            v-model="selectValue"
            slot="prepend"
            placeholder="请选择筛选类型"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-input>
        <el-button
          v-if="tabName != 'list'"
          type="primary"
          size="medium"
          @click="addDevInToGroups"
          >添加设备</el-button
        >
        <el-button
          v-show="multipleSelection.length > 0 && tabName == 'list'"
          type="primary"
          size="medium"
          @click="shareDevSelect"
          >分享设备</el-button
        >
        <el-button
          v-show="multipleSelection.length > 0"
          type="danger"
          size="medium"
          @click="deleteSelect"
          >删除设备</el-button
        >
        <el-button
          v-show="tabName != 'list'"
          type="danger"
          size="medium"
          @click="deleteGroup"
          >删除群组</el-button
        >
        <el-dialog
          title="添加设备"
          :visible.sync="addDevDialogVisible"
          width="60%"
        >
          <el-table
            :data="addDevTableData"
            @selection-change="addDevHandleSelectionChange"
            style="width: 90%; margin: 0 auto"
          >
            <el-table-column type="selection" align="center"></el-table-column>
            <el-table-column
              type="index"
              width="50"
              label="#"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="设备名称"
              align="center"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="type" label="设备类型" align="center">
            </el-table-column>
            <el-table-column
              prop="lastLink"
              label="最后通讯时间"
              width="250"
              align="center"
              sortable
            >
            </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDevDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="updateGroup">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog title="分享" :visible.sync="qrcodeDialogVisible" width="30%">
          <div id="qrcode" ref="qrcode"></div>
        </el-dialog>
      </div>
    </div>
    <div class="fullh" style="background-color: #fff">
      <el-table
        :data="tableList"
        border
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column type="index" width="50" label="#" align="center">
        </el-table-column>
        <el-table-column prop="name" label="设备名称" align="center" sortable>
        </el-table-column>
        <el-table-column prop="type" label="设备类型" align="center">
        </el-table-column>
        <el-table-column prop="signal" label="信号值" width="80" align="center">
        </el-table-column>
        <el-table-column
          prop="lastLink"
          label="上次通讯时间"
          align="center"
          sortable
        >
        </el-table-column>
        <el-table-column prop="IMSI" label="IMSI" align="center" sortable>
        </el-table-column>
        <el-table-column
          prop="versions"
          label="设备程序版本"
          width="250"
          align="center"
          sortable
        >
        </el-table-column>
        <el-table-column label="设备位置" width="120" align="center">
          <template slot-scope="scope">
            <el-button
              @click="
                MapdialogVisible = true;
                ShowMap(scope.row);
              "
              type="primary"
              size="small"
              icon="el-icon-view"
              :disabled="scope.row.locad == '' ? true : false"
              >查看位置</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              @click="DevListhHndleClick(scope.row)"
              type="primary"
              size="small"
              icon="el-icon-view"
            >
              查看</el-button
            >
            <el-button
              v-show="false"
              type="primary"
              size="small"
              @click="operationSelect"
              >关联查询</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[
          30,
          50,
          100,
          tableList
            .filter(
              (data) =>
                !this.searchName ||
                data.name.toLowerCase().includes(this.searchName.toLowerCase())
            )
            .filter(
              (data) =>
                !this.selectValue ||
                data.type.toLowerCase().includes(this.selectValue.toLowerCase())
            ).length,
        ]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="
          tableData
            .filter(
              (data) =>
                !this.searchName ||
                data.name.toLowerCase().includes(this.searchName.toLowerCase())
            )
            .filter(
              (data) =>
                !this.selectValue ||
                data.type.toLowerCase().includes(this.selectValue.toLowerCase())
            ).length
        "
      >
      </el-pagination>

      <el-dialog
        title="设备位置"
        center
        :visible.sync="MapdialogVisible"
        width="800px"
      >
        <el-amap
          vid="amapDemo"
          :zoom="zoom"
          :center="center"
          style="width: 100%; height: 500px"
        >
          <el-amap-marker :position="center"></el-amap-marker>
        </el-amap>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueAMap from "vue-amap";
import axios from "axios";
import QRCode from "qrcodejs2";
import { groupDisp, getDevList } from "@/api/require";

Vue.use(VueAMap);
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: "499d3d48bdc56b897c64716ac6730ddc",
  // 插件集合
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: "1.4.4",
});

export default {
  props: {
    tableData: Array, //表格数据
    tabName: String, //区分哪个Tab,
    id: String, //群组id
  },
  data() {
    return {
      qrcode: null,
      link: "http://cloud.vnata.cn/",
      qrcodeDialogVisible: false, //分享
      groupDialogVisible: false, //添加分组
      currentPage: 1, //  el-pagination 初始页
      pagesize: 30, //  el-pagination 每页的数据
      addDevDialogVisible: false, //添加设备对话框
      addDevTableData: [], //未添加设备table
      addDevList: [], //选中需要添加的设备列表
      groupValue: "", //分组value
      devListData: [], //所有设备列表
      searchName: "", //搜索
      selectValue: "", //过滤设备类型
      options: [
        //过滤设备类型
      ],
      MapdialogVisible: false, //地图对话框
      //地图
      zoom: 15, //级别
      center: [116.397428, 39.90923],

      //选中
      multipleSelection: [],
    };
  },
  components: {
    // eslint-disable-next-line
    QRCode,
  },
  created() {
    this.getOptionsType();
    this.setTableData();
  },
  methods: {
    // 生成二维码
    generateQrcode() {
      // eslint-disable-next-line
      this.qrcode = new QRCode("qrcode", {
        width: 124,
        height: 124,
        text: this.link, //内容
      });
    },
    // 群组操作
    // async groupDispManagement(type, devs, groupId) {
    //   this.groupDialogVisible = true;
    //   const { data: res } = await groupDisp(type, devs, groupId);
    //   console.log(res);
    // },
    //获取设备类型
    async getOptionsType() {
      const { data: res } = await axios.get("/API/GetDevType.php");
      this.options = res;
    },
    //获取设备列表数据
    async setTableData() {
      const { data: res } = await getDevList();
      this.devListData = res;
    },
    //添加设备进分组
    addDevInToGroups() {
      this.addDevTableData = [];
      let devListIndex = [];
      this.tableData.forEach((el) => {
        devListIndex.push(el.Index);
      });
      this.devListData.forEach((el) => {
        if (devListIndex.indexOf(el.Index) == -1) {
          this.addDevTableData.push(el);
        }
      });
      this.addDevDialogVisible = true;
    },
    //添加设备提交
    async updateGroup() {
      const { data: res } = await groupDisp(2, null, this.addDevList, this.id);
      console.log(res);
      if (res.code == 0) {
        this.$emit("update");
        this.addDevDialogVisible = false;
        return this.$message({
          type: "success",
          message: "添加成功!",
        });
      }
      return this.$message({
        type: "error",
        message: res.result.Describe,
      });
    },
    //查看地图
    ShowMap(row) {
      this.center = row.locad.split(",");
    },
    DevListhHndleClick(row) {
      this.$router.push({
        path:
          "/equipment-detail/" +
          row.Index +
          (row.AlarmSubIndex ? "?index=" + row.AlarmSubIndex : ""),
        query: { versions: row.versions },
      });
    },
    //表格选中
    handleSelectionChange(val) {
      // this.multipleSelection = val;
      this.multipleSelection = val.map((el) => el.Index);
    },
    //添加设备表格选中
    addDevHandleSelectionChange(val) {
      this.addDevList = val.map((el) => el.Index);
    },
    //操作所选
    operationSelect() {
      console.log(this.multipleSelection);
    },
    //分享
    shareDevSelect() {
      this.qrcodeDialogVisible = true;
      if (this.qrcode) this.$refs.qrcode.innerHTML = ""; // clear the code.
      this.$nextTick(function () {
        this.generateQrcode();
      });
    },
    // 删除所选
    deleteSelect() {
      let title;
      if (this.tabName) {
        title =
          "此操作将从设备列表删除所选,需重新添加设备。设备列表不存在此设备！";
      } else {
        title = "此操作将从此列表删除所选,设备列表还会存在此设备。";
      }
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await groupDisp(
            3,
            null,
            this.multipleSelection,
            this.id
          );
          if (res.code == 0) {
            this.$emit("updatelist");
            this.$emit("update");
            return this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          return this.$message({
            type: "error",
            message: res.result.Describe,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //删除群组
    deleteGroup() {
      this.$confirm("确定删除该群组?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await groupDisp(4, null, null, this.id);
          if (res.code == 0) {
            this.$emit("delete");
            this.$emit("update");
            return this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          return this.$message({
            type: "error",
            message: res.result.Describe,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //table颜色
    tableRowClassName({
      row,
      // rowIndex
    }) {
      if (row.NetworkState == 1) {
        return "net-anomaly-row";
      } else if (row.AlarmCout > 0) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
    // size-change	pageSize 改变时会触发	每页条数size
    // current-change	currentPage 改变时会触发	当前页currentPage
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize); // 每页下拉显示数据
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage); // 点击第几页
    },
  },
  computed: {
    tableList() {
      return this.tableData
        .filter(
          (data) =>
            !this.searchName ||
            data.name.toLowerCase().includes(this.searchName.toLowerCase())
        )
        .filter(
          (data) =>
            !this.selectValue ||
            data.type.toLowerCase().includes(this.selectValue.toLowerCase())
        )
        .slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        ); //分页;
    },
  },
};
</script>
<style lang="scss" scoped>
// .el-page-header {
//   float: left;
//   padding: 18px;
// }
.top {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
}
.el-menu.el-menu--horizontal {
  float: right;
  background-color: #f9fafc;
}
// .demo-block {
//   border: 1px solid #ebebeb;
//   border-radius: 3px;
//   transition: 0.2s;
//   height: 59px;
// }
.card_body {
  padding: 20px;
  background: #67c23a;
  color: white;
  line-height: 20px;
}
.card_header {
  padding: 18px 20px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
}

.box-card {
  width: 220px;
  float: left;
  text-align: center;
  margin: 10px;
}
.input-with-select {
  width: 430px;
  padding: 10px;
}
// .el-select .el-input {
//   width: 200px !important;
// }
.qrcode {
  width: 250px;
  height: 250px;
}
</style>