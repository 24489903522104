<template>
  <div class="equipment">
    <div class="fullh">
      <el-row
        type="flex"
        :gutter="20"
        justify="space-around"
        style="height: 25%"
      >
        <el-col :span="23" style="height: 100%">
          <el-row
            type="flex"
            :gutter="20"
            class="row-bg"
            justify="space-around"
            style="height: 100%"
          >
            <el-col :span="6">
              <div class="bg-purple">
                <div class="overview panel">
                  <div class="inner">
                    <el-row class="fullw fullh">
                      <el-col :span="24" class="fullh">
                        <el-row
                          type="flex"
                          style="height: 90%"
                          justify="center"
                          align="middle"
                        >
                          <div
                            ref="RefDevCout"
                            class="fullw fullh"
                            @click="$router.push('/equipment-list')"
                          ></div>
                        </el-row>
                        <el-row type="flex" justify="center">
                          <span>设备总数</span>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="bg-purple">
                <div class="overview panel">
                  <div class="inner">
                    <el-row class="fullw fullh">
                      <el-col :span="24" class="fullh">
                        <el-row
                          type="flex"
                          style="height: 90%"
                          justify="center"
                          align="middle"
                        >
                          <div
                            ref="RefLink"
                            class="fullw fullh"
                            @click="$router.push('/equipment-list')"
                          ></div>
                        </el-row>
                        <el-row type="flex" justify="center">
                          <span>在线比率</span>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="bg-purple">
                <div class="overview panel">
                  <div class="inner">
                    <el-row class="fullw fullh">
                      <el-col :span="24" class="fullh">
                        <el-row
                          type="flex"
                          style="height: 90%"
                          justify="center"
                          align="middle"
                        >
                          <div
                            ref="RefAlarmCout"
                            class="fullw fullh"
                            @click="$router.push('/alarm-statistics')"
                          ></div>
                        </el-row>
                        <el-row type="flex" justify="center">
                          <span>告警信息</span>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="bg-purple">
                <div class="overview panel">
                  <div class="inner">
                    <el-row class="fullw fullh">
                      <el-col :span="24" class="fullh">
                        <el-row
                          type="flex"
                          style="height: 90%"
                          justify="center"
                          align="middle"
                        >
                          <div
                            ref="RefAlarmType"
                            class="fullw fullh"
                            @click="$router.push('/alarm-statistics')"
                          ></div>
                        </el-row>
                        <el-row type="flex" justify="center">
                          <span>告警类型</span>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        :gutter="20"
        justify="space-around"
        style="height: 40%"
        class="row-bg"
      >
        <el-col :span="23" style="height: 95%">
          <div class="bg-Hist">
            <div class="overview panel">
              <div class="inner" ref="RefAlarm"></div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        :gutter="20"
        justify="space-around"
        style="height: 35%"
        class="row-bg"
      >
        <el-col :span="23" style="height: 95%">
          <div class="bg-Hist">
            <div class="overview panel">
              <div class="inner" ref="RefHistoryAlarm"></div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getHomeDev } from "@/api/require";

import {
  DevCoutOption,
  Linkoption,
  AlarmCoutOption,
  color,
  AlarmTypeOption,
  Alarmoption,
  Historyoption,
} from "@/api/chartData";
export default {
  name: "equipment",
  data() {
    return {
      HomeData: [], //主页数据
      // echart
      RefHistoryAlarmChart: 0,
      RefAlarmChart: 0,
      RefLinkChart: 0,
      RefAlarmCoutChart: 0,
      RefAlarmTypeChart: 0,
      RefDevCoutChart: 0,

      // 定时器
      timer: 0,
    };
  },
  props: {
    item: Object,
  },
  created() {
    this.getHomeData();
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      this.RefDevCoutChart = this.$echarts.init(this.$refs.RefDevCout);
      this.RefAlarmCoutChart = this.$echarts.init(this.$refs.RefAlarmCout);
      this.RefAlarmTypeChart = this.$echarts.init(this.$refs.RefAlarmType);
      this.RefLinkChart = this.$echarts.init(this.$refs.RefLink);
      this.RefAlarmChart = this.$echarts.init(this.$refs.RefAlarm);
      this.RefHistoryAlarmChart = this.$echarts.init(
        this.$refs.RefHistoryAlarm
      );
      this.timer = setInterval(() => {
        this.getHomeData();
      }, 5000);
    },
    // 获取并渲染数据
    async getHomeData() {
      const res = await getHomeDev();
      DevCoutOption.title.text = res.data.DevCout;
      this.RefDevCoutChart.setOption(DevCoutOption);

      Linkoption.series[0].axisLine.lineStyle.color[0][0] = res.data.Link / 100;
      Linkoption.series[0].data[0].value = res.data.Link;
      Linkoption.series[1].axisLine.lineStyle.color[0][0] = res.data.Link / 100;
      Linkoption.series[1].data[0].value = res.data.Link;
      Linkoption.series[2].data[0].value = res.data.Link;
      this.RefLinkChart.setOption(Linkoption);

      AlarmCoutOption.series[0].data = res.data.HisAlarmNum.AlarmNum;
      AlarmCoutOption.series[1].data = res.data.HisAlarmNum.RecoverNum;
      AlarmCoutOption.xAxis[0].data = res.data.HisAlarmNum.date;
      this.RefAlarmCoutChart.setOption(AlarmCoutOption);

      var AlarmTypeData = [];
      for (var i = 0; i < res.data.AlarmType.length; i++) {
        AlarmTypeData.push({
          value: res.data.AlarmType[i].value,
          name: res.data.AlarmType[i].name,
          itemStyle: {
            normal: {
              borderWidth: 5,
              shadowBlur: 20,
              borderColor: color[i],
              shadowColor: color[i],
            },
          },
        });
      }
      AlarmTypeOption.series[0].data = AlarmTypeData;
      AlarmTypeOption.title.text = res.data.AlarmCout + "个异常";
      this.RefAlarmTypeChart.setOption(AlarmTypeOption);

      if (
        JSON.stringify(this.HomeData.AlarmList) !=
        JSON.stringify(res.data.AlarmList)
      ) {
        Alarmoption.series[0].data = res.data.AlarmList;
        this.RefAlarmChart.setOption(Alarmoption);
        this.RefAlarmChart.on("click", (params) => {
          console.log(params);
          this.$router.push(
            "/equipment-detail/" +
              params.data.Devindex +
              "?index=" +
              params.data.AlramList[0].SubIndex
          );
        });
        this.$set(this, "HomeData", res.data);
      }

      Historyoption.series[0].data = res.data.HisAlarm.Num;
      Historyoption.series[1].data = res.data.HisAlarm.Num;
      Historyoption.xAxis.data = res.data.HisAlarm.date;
      this.RefHistoryAlarmChart.setOption(Historyoption);
    },
  },
  beforeDestroy() {
    console.log("xiao");
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.equipment {
  widows: 100%;
  height: 100%;
}
.fullw {
  width: 100%;
}

.fullh {
  height: 100%;
}
.bg-purple {
  height: 100%;
  max-width: 1510px;
}
.overview {
  height: 100%;
}

.panel {
  box-sizing: border-box;
  border: 2px solid red;
  border-image: url(../../assets/images/border.png) 51 38 21 132;
  border-width: 2.125rem 1.583rem 0.875rem 5.5rem;
  position: relative;
  background: rgba(255, 255, 255, 0.04) url(../../assets/images/line.png);
}

.overview .inner {
  display: flex;
  justify-content: space-between;
}

.inner {
  position: absolute;
  top: -2.125rem;
  right: -1.583rem;
  bottom: -0.875rem;
  left: -5.5rem;
  padding: 1rem 1.5rem;
  color: #66b1ff;
  font-size: 1.1rem;
}
.bg-Hist {
  height: 100%;
}
.row-bg {
  padding: 10px 10px;
}
</style>