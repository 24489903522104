<template>
  <div class="list">
    <Table :tableData="tableData"></Table>
    <!-- <el-button type="primary" @click="exportDataListExcel">数据导出</el-button> -->
  </div>
</template>
<script>
import { getDevList, getDevData } from "@/api/require";
import Table from "@/components/table/Table";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
export default {
  name: "List",
  components: {
    Table,
  },
  data() {
    return {
      tableData: [], //所有设备列表
      //导出数据临时数据存储
      devArray: [], //axios请求数据
      devNameArr: [],
      timerID: 0,
    };
  },
  created() {
    this.setTableData();
  },
  methods: {
    async setTableData() {
      const { data: res } = await getDevList();
      this.tableData = res;
      // this.timer();
    },
    timer() {
      clearInterval(this.timerID);
      this.timerID = setInterval(async () => {
        const { data: res } = await getDevList();
        this.tableData = res;
      }, 5000);
    },
    // 导出表格
    exportDataListExcel() {
      var Temptable = [];
      var wb = XLSX.utils.book_new(); //新建表格
      var wbout, ws;

      this.loading = this.$loading({
        lock: true,
        text: "等待数据加载....",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      Temptable.push({
        A: "子设备名称",
        B: "数据类型",
        C: "数据描述",
        D: "当前值",
      });
      this.tableData.forEach((devItem, devIndex) => {
        this.devNameArr.push(devItem, name);

        this.tableData[devIndex].DevSlave.forEach((typeItem, typeIndex) => {
          //遍历子设备类型
          this.tableData[devIndex].DevSlave[typeIndex].forEach((slaveItem) => {
            getDevData(devItem.Index, slaveItem.Index).then(({ data: res }) => {
              res.Status.forEach((Data) => {
                var row = {
                  A: slaveItem.Desc,
                  B: slaveItem.Type,
                  C: Data.Name,
                  D: Data.AlarmDesc[Data.Value],
                };
                Temptable.push(row);
              });
              res.Data.forEach((Data) => {
                var row = {
                  A: slaveItem.Desc,
                  B: slaveItem.Type,
                  C: Data.Name,
                  D: Data.Value + Data.Unit,
                };
                Temptable.push(row);
              });
              res.Rated.forEach((Data) => {
                var row = {
                  A: slaveItem.Desc,
                  B: slaveItem.Type,
                  C: Data.Name,
                  D: Data.Value + Data.Unit,
                };
                Temptable.push(row);
              });

              ws = XLSX.utils.json_to_sheet(Temptable, {
                header: ["A", "B", "C", "D"],
                skipHeader: true,
              });
              // ws = XLSX.utils.json_to_sheet(Temptable);
              //设置列宽
              ws["!cols"] = [
                {
                  width: 15,
                },
                {
                  width: 15,
                },
                {
                  width: 15,
                },
                {
                  width: 15,
                },
              ];
              XLSX.utils.book_append_sheet(wb, ws, devItem.name); //新建Sheet
              wbout = XLSX.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
              });
            });
          });
        });
      });
      setTimeout(() => {
        var timestamp = new Date().format("yyyy年MM月dd日hh小时mm分ss秒");
        try {
          saveAs(
            //Blob 对象表示一个不可变、原始数据的类文件对象。
            //Blob 表示的不一定是JavaScript原生格式的数据。
            //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
            //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
            new Blob([wbout], {
              type: "application/octet-stream",
            }),
            //设置导出文件名称
            "实时数据" + timestamp + ".xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.loading.close();
        return wbout;
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timerID);
    this.timerID = null;
  },
};
</script>
<style lang="scss" scoped>
.list {
  height: 100%;
  width: 100%;
  background-color: #fff;
}
</style>