import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/normalize.css'
import './assets/css/global.css'

import VueCookie from "vue-cookies";
Vue.prototype.$cookie = VueCookie

import echarts from 'echarts'
Vue.prototype.$echarts = echarts
import 'echarts-liquidfill'

//全局格式化时间
import './utils/format-date'

import {
  Row,
  Button,
  Col,
  Image,
  Menu
} from 'element-ui';

Vue.use(Row)
Vue.use(Button)
Vue.use(Col)
Vue.use(Image)
Vue.use(Menu)

//两个项目 true表示自己的  false 表示浩源的
Vue.prototype.$developmentProject = false


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')