<template>
  <div class="nav-menu">
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      @select="handleSelect"
      background-color="#001529"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <div class="logo">
        <el-image
          v-if="$developmentProject"
          :src="require('@/assets/images/vnata.svg')"
          fit="scale-down"
        ></el-image>
        <el-image
          v-else
          :src="require('@/assets/images/logo.png')"
          fit="scale-down"
        ></el-image>
      </div>
      <!-- :disabled="index == 4 ? true : false" -->
      <el-menu-item
        v-for="(item, index) in menuArray"
        :key="index"
        :index="menuActive[index]"
      >
        <i :class="menuIcon[index]"></i>
        <span slot="title">{{ item }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "navMenu",
  data() {
    return {
      active: "1", //选中项
      //图标类名
      menuIcon: [
        "el-icon-s-home",
        "el-icon-s-order",
        "el-icon-s-order",
        "el-icon-s-management",
        // "el-icon-s-management",
        // "el-icon-s-data",
      ],
      //导航菜单
      menuArray: [
        "设备概览",
        // "设备列表",
        "实时告警",
        "群组管理",
        "数据管理",
        // "系统设置",
      ],
      //高亮
      menuActive: [
        "/equipment-overview",
        // "/equipment-list",
        "/alarm-statistics",
        "/group-management",
        "/data-export",
      ],
    };
  },
  created() {},
  methods: {
    handleSelect(key) {
      this.$router.push(key);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-menu {
  height: 100%;
  .el-menu {
    height: 100%;
    .logo {
      padding: 15px 15px;
      color: #fafbfc;
      font-size: 43px;
      line-height: 50px;
      text-align: center;
      background: #001529;
      .el-image {
        width: 180px;
      }
    }
  }
}
</style>