<template>
  <div class="footer">
    <span> Copyright © 2004 - 2020 {{ name }} 版权所有 </span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: this.$developmentProject
        ? "广州市维能达软件科技有限公司"
        : "浩沅集团股份有限公司",
    };
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 100%;
  span {
    text-align: center;
    color: rgb(185, 186, 187);
    width: 100%;
    line-height: 60px;
  }
}
</style>