<template>
  <div class="layout">
    <el-container style="height: 100%">
      <el-aside>
        <NavMenu></NavMenu>
      </el-aside>
      <el-container>
        <el-header>
          <Header></Header>
        </el-header>
        <el-main>
          <router-view :key="$route.fullPath"></router-view>
        </el-main>
        <el-footer>
          <Footer></Footer>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavMenu from "./components/navmenu/index";
import Header from "./components/header/index";
import Footer from "./components/footer/index";

export default {
  name: "layout",
  components: {
    NavMenu,
    Header,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
.layout {
  height: 100%;
}
.el-header {
  height: 60px;
  line-height: 60px;
  background: linear-gradient(to right, #000c3b, #000c3b, #000c3b);
  color: #333;
}

.el-footer {
  background: linear-gradient(to right, #000c3b, #000c3b, #000c3b);
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.el-aside {
  width: 240px !important;
  background-color: #001529;
  color: #333;
  line-height: 200px;
}
.el-main {
  background: linear-gradient(to right, #000c3b, #000c3b, #000c3b);
  // background: #fff;
  color: #333;
  overflow: hidden;
  min-width: 1400px;
  min-height: 750px;
  height: 100%;
  overflow: auto;
}
</style>
