Date.prototype.format = function(format) {
	let o = {
	"M+": this.getMonth() + 1,
	"d+": this.getDate(),
	"H+": this.getHours(),
	"m+": this.getMinutes(),
	"s+": this.getSeconds(),
	"q+": Math.floor((this.getMonth() + 3) / 3),//季度
	"f+": this.getMilliseconds(),//毫秒
	};
	if(/(y+)/.test(format))
		format = format.replace(RegExp.$1, this.getFullYear() + "").substr(4 - RegExp.$1.length);
	for(let k in o) {
		if(new RegExp("(" + k + ")").test(format))
			format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
	}
	return format;
}

//格式化时间 使用方法 yyyy年MM月dd日 HH时mm分ss秒