<template>
  <div class="excel">
    <el-tabs v-model="activeName" type="border-card">
      <!-- 
      <el-tab-pane label="实时告警导出" name="3">
        <Excel
          :tableData="realTime"
          :columnProp="[
            'Devname',
            'Sub_name',
            'AlarmTime',
            'AlarmDesc',
            'AlarmSuggest',
          ]"
          defaultFilename="实时警告"
        ></Excel>
      </el-tab-pane> -->
      <el-tab-pane label="实时数据" name="1">
        <Excel
          :tableData="devList"
          :columnProp="['name', 'type', 'lastLink', 'IMSI', 'versions']"
          defaultFilename="实时数据"
          :columnLabel="[
            '设备名称',
            '设备类型',
            '上次通讯时间',
            'IMSI',
            '设备程序版本',
          ]"
        ></Excel>
      </el-tab-pane>
      <el-tab-pane label="历史数据" name="2">
        <div class="datePicker">
          <el-cascader
            v-model="hisDataValue"
            :options="devListOptions"
            :props="{ expandTrigger: 'hover' }"
            placeholder="试试看搜索吧"
            filterable
          ></el-cascader>
          <el-date-picker
            v-model="hisDataDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            style="margin: 0 10px"
          >
          </el-date-picker>
          <el-button type="primary" @click="getDevHisData"
            >获取历史数据</el-button
          >
        </div>
        <el-dialog
          title="提示"
          :visible.sync="hisDataVisualizationDialog"
          width="50%"
        >
          <!-- <el-transfer
            v-model="hisDataVisualizationValue"
            :data="hisDataVisualizationData"
          ></el-transfer> -->
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <div style="margin: 15px 0"></div>
          <el-checkbox-group
            v-model="hisDataVisualizationValue"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox
              v-for="(item, index) in hisDataVisualizationData"
              :label="item.key"
              :key="index"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
          <span slot="footer" class="dialog-footer">
            <el-button @click="hisDataVisualizationDialog = false"
              >取 消</el-button
            >
            <el-button type="primary" @click="getVisualizationData"
              >可视化查看</el-button
            >
          </span>
        </el-dialog>
        <div
          class="hisdata-chart"
          ref="lineChart"
          v-bind:style="'height:' + echartHeight + 'px'"
        ></div>
      </el-tab-pane>
      <el-tab-pane label="历史告警" name="3">
        <div class="datePicker">
          <!-- <el-cascader
            v-model="hisAlarmValue"
            :options="devListOptions"
            :props="{ expandTrigger: 'hover' }"
            placeholder="试试看搜索吧"
            filterable
          ></el-cascader> -->
          <el-cascader
            v-model="hisAlarmValue"
            :options="hisDevListOptions"
            :props="{ checkStrictly: true }"
            placeholder="试试看搜索吧"
            filterable
            clearable
          ></el-cascader>
          <el-date-picker
            v-model="hisAlarmDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            style="margin: 0 10px"
          >
          </el-date-picker>
          <el-button type="primary" @click="getHisAlarData"
            >获取历史告警</el-button
          >
        </div>
        <Excel
          :tableData="hisAlarmData"
          :columnProp="[
            'Devname',
            'Sub_name',
            'AlarmTime',
            'AlarmDesc',
            'AlarmSuggest',
          ]"
          defaultFilename="历史告警"
          :columnLabel="['设备名称', '子设备名称', '告警时间', '描述', '提示']"
          :rowChange="true"
          @update="getHisAlarData"
        ></Excel>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import {
  getAlarmList,
  getDevList,
  getHisAlarmData,
  getDevHistoryData,
  getDataName,
} from "@/api/require";
import Excel from "@/components/excel/index";
export default {
  name: "export-excel",
  components: {
    Excel,
  },
  data() {
    return {
      activeName: "1", //tab选中栏
      checkAll: false, //全选
      isIndeterminate: false, //半选状态
      devList: [], //设备列表
      realTime: [], //实时告警
      hisDataDate: [new Date(new Date() - 24 * 60 * 60 * 1000), new Date()], //历史数据时间选择
      hisAlarmDate: [new Date(new Date() - 24 * 60 * 60 * 1000), new Date()], //时间
      hisAlarmData: [], //历史告警
      devHisData: [], //历史记录
      devListOptions: [], //设备option
      hisDevListOptions: [], //历史记录option
      hisAlarmValue: [0], //历史告警 设备选择
      hisDataValue: [], //历史数据 设备选择
      hisDataVisualizationDialog: false, //历史数据数据可视化对话框
      hisDataVisualizationValue: [], //可视化选择的值
      hisDataVisualizationData: [], //可视化列表选择
      hisDataVisualization: [], //可视化数据
      echartHeight: window.screen.height - 342,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getDevListData();
    this.getRealTimeData();
  },
  methods: {
    //获取设备列表
    async getDevListData() {
      const { data: res } = await getDevList();
      this.devList = res;
      res.forEach((el, index) => {
        this.devListOptions.push({
          value: el.Index,
          label: el.name,
          children: [],
        });
        el.DevSlave.forEach((el1) => {
          el1.forEach((el2) => {
            this.devListOptions[index].children.push({
              value: el2.Index,
              label: el2.Type,
            });
          });
        });
      });
      this.hisDataValue = [
        this.devListOptions[0].value,
        this.devListOptions[0].children[0].value,
      ];
      this.hisDevListOptions = JSON.parse(JSON.stringify(this.devListOptions));
      this.hisDevListOptions.unshift({
        value: 0,
        label: "全部设备",
      });
      // console.log(res);
    },
    //获取实时告警数据
    async getRealTimeData() {
      const { data: res } = await getAlarmList();
      this.realTime = res.AlarmList;
      // console.log(res);
    },

    //获取历史数据
    async getDevHisData() {
      if (this.hisDataValue.length == 0) {
        return this.$message({
          message: "请选择查询设备！",
          type: "warning",
        });
      }
      if (this.hisDataDate.length == 0) {
        return this.$message({
          message: "请选择查询时间！",
          type: "warning",
        });
      }
      // 获取设备子节点的数据列
      const { data: res } = await getDataName(
        this.hisDataValue[0],
        this.hisDataValue[1]
      );
      this.hisDataVisualizationData = [];
      res.forEach((el) => {
        this.hisDataVisualizationData.push({
          key: el.ParamName,
          label: el.ParamDesc,
        });
      });
      this.hisDataVisualizationDialog = true;
    },
    //可视化查看
    async getVisualizationData() {
      console.log(this.$refs.lineChart);
      this.hisDataVisualizationDialog = false;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await getDevHistoryData(
        this.hisDataValue[0],
        this.hisDataValue[1],
        this.dateFormat("YY-mm-dd HH:MM:SS", this.hisDataDate[0]),
        this.dateFormat("YY-mm-dd HH:MM:SS", this.hisDataDate[1]),
        this.hisDataVisualizationValue
      );
      console.log(res);
      loading.close();
      if (res.code == 0) {
        let ser = JSON.parse(JSON.stringify(res.charts.series));
        console.log(ser);
        this.hisDataVisualizationValue.forEach((el1, index) => {
          //绑定值
          this.hisDataVisualizationData.forEach((el2) => {
            if (el1 == el2.key) {
              ser[index].type = "line";
              ser[index].name = el2.label;
            }
          });
        });

        console.log(ser);
        const option = {
          legend: {
            top: "10%",
          },
          tooltip: {
            trigger: "axis",
            position: function (pt) {
              return [pt[0], "0%"];
            },
          },
          grid: {
            top: "20%",
            left: "4%",
            right: "1%",
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 20,
            },
            {
              start: 0,
              end: 20,
            },
          ],
          xAxis: {
            type: "category",
            data: res.charts.xAxis,
          },
          yAxis: {
            type: "value",
          },
          series: ser,
        };
        const lineChart = this.$echarts.init(this.$refs.lineChart);
        lineChart.setOption(option);
      } else {
        console.log("1222");
      }
    },
    //获取历史告警
    async getHisAlarData() {
      if (this.hisAlarmValue.length == 0) {
        return this.$message({
          message: "请选择查询设备！",
          type: "warning",
        });
      }
      if (this.hisAlarmDate.length == 0) {
        return this.$message({
          message: "请选择查询时间！",
          type: "warning",
        });
      }
      const { data: res } = await getHisAlarmData(
        this.hisAlarmValue[0] || this.hisAlarmValue[0],
        this.hisAlarmValue[1] || 0, //如果没选择设备index就默认全部 0
        this.dateFormat("YY-mm-dd HH:MM:SS", this.hisAlarmDate[0]),
        this.dateFormat("YY-mm-dd HH:MM:SS", this.hisAlarmDate[1])
      );
      if (res.length == 0) {
        this.$message({
          message: "数据为空",
          type: "warning",
        });
      } else {
        this.$message({
          message: "获取成功",
          type: "success",
        });
      }
      this.hisAlarmData = res;
    },
    //选框状态
    handleCheckAllChange(val) {
      console.log(val);
      this.hisDataVisualizationValue = val
        ? this.hisDataVisualizationData.map((el) => {
            return el.key;
          })
        : [];

      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.hisDataVisualizationData.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.hisDataVisualizationData.length;
    },
    //  格式化时间戳
    dateFormat(fmt, date) {
      let ret;
      let opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
};
</script>
<style lang="scss" scoped>
.excel {
  width: 100%;
  // height: 100%;
  min-height: 100%;
  margin: 0 auto;
  background-color: #fff;
  .el-tabs--border-card {
    height: auto;
    // min-height: 809px;
    min-height: 100%;
  }
  .el-tabs {
    height: 100%;
    .el-tabs__content {
      width: 100%;
      height: 100%;
    }
    .datePicker {
      float: left;
      position: relative;
      z-index: 99;
    }
  }
  .hisdata-chart {
    width: 100%;
    height: 100%;
    // min-height: 738px;
  }
}
</style>