<template>
  <div class="group" style="background-color: #fff">
    <el-tabs v-model="activeName" type="card" :before-leave="beforeLeave">
      <el-tab-pane label="设备列表" name="list" key="list">
        <Table
          :tableData="tableData"
          tabName="list"
          @updatelist="setTableData"
        ></Table>
      </el-tab-pane>
      <el-tab-pane
        :label="item.GroupName"
        :name="String(index)"
        v-for="(item, index) in groupData"
        :key="index"
      >
        <Table
          :tableData="item.DevS"
          :id="item.GroupID"
          @update="getGroupDevData"
          @delete="deleteGroup"
          height="100%"
        ></Table>
      </el-tab-pane>
      <el-tab-pane label="添加分组" name="add" key="add"
        ><span slot="label" class="el-icon-plus" style="font-weight: bold"
          >添加分组
        </span></el-tab-pane
      >
    </el-tabs>
    <el-dialog
      title="添加分组"
      :visible.sync="addGroupDialogVisible"
      width="30%"
    >
      <el-input
        v-model="groupName"
        placeholder="请输入要添加的分组名称"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGroupDialogVisible = false" size="medium"
          >取 消</el-button
        >
        <el-button type="primary" @click="addGroup" size="medium"
          >添 加</el-button
        >
      </span>
    </el-dialog>
    <div class="null" v-if="tagShow">暂无数据</div>
  </div>
</template>
<script>
import { getGroupDev, getDevList, groupDisp } from "@/api/require";
import Table from "@/components/table/Table";
export default {
  name: "Group",
  components: {
    Table,
  },
  data() {
    return {
      tableData: [], //所有设备列表
      activeName: "list",
      groupData: [],
      tagShow: false,
      timer: 0,
      addGroupDialogVisible: false, //添加分组
      groupName: "",
    };
  },
  created() {
    this.getGroupDevData();
    this.setTableData();
  },
  methods: {
    //获取群组数据
    async getGroupDevData() {
      console.log("更新数据啦");
      const { data: res } = await getGroupDev();
      if (res == null) return (this.tagShow = true);
      this.groupData = res;
      // clearInterval(this.timer);
      // this.timer = setInterval(() => {
      //   this.getGroupDevData();
      // }, 10000);
    },
    //获取设备列表
    async setTableData() {
      const { data: res } = await getDevList();
      this.tableData = res;
    },
    //选项卡开启前
    /* 活动标签切换时触发 */
    beforeLeave(currentName) {
      //重点，如果name是add，则什么都不触发
      if (currentName == "add") {
        this.addGroupDialogVisible = true;
        return false;
      }
    },
    //添加分组
    async addGroup() {
      const { data: res } = await groupDisp(1, this.groupName, null, null);
      this.getGroupDevData();
      this.addGroupDialogVisible = false;

      if (res.code == 0) {
        this.groupName = "";
        return this.$message({
          type: "success",
          message: "添加成功!",
        });
      }
      return this.$message({
        type: "error",
        message: res.result.Describe,
      });
    },
    //删除群组触发
    deleteGroup() {
      this.activeName = "list";
    },
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.group {
  width: 100%;
  height: 100%;
  .el-tabs__nav-wrap {
    width: 90%;
    margin: 0 auto;
  }
  .null {
    margin: 0 auto;
    width: 100px;
  }
}
</style>