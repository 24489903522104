<template>
  <div class="header">
    <div class="caption-title clearfix">
      <i class="title-left"> <img src="@/assets/images/title-left.png" /></i>
      <p class="title-left-title-font">广州白云机场TI航站楼EPS电源监控平台</p>
      <i class="title-left"> <img src="@/assets/images/title-right.png" /></i>
    </div>
    <el-dropdown>
      <el-avatar :size="35" :src="circleUrl"></el-avatar>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="exit">退出登录</el-dropdown-item>
        <el-dropdown-item @click.native="$router.push('/system-setting')"
          >系统设置</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import VueCookie from "vue-cookies";

export default {
  name: "header",
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  created() {
    //获取头像地址 并转换成url
    this.circleUrl = decodeURIComponent(VueCookie.get("circleUrl"));
  },
  methods: {
    exit() {
      console.log("exit");
      // 清除cookies并刷新
      document.cookie = "userid=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      // this.$cookie.remove("userid");
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.caption-title {
  height: 60px;
  font-size: 2rem;
  font-weight: 900;
  line-height: 65px;
}

.title-left {
  float: left;
  display: block;
}

.title-left img {
  width: 100%;
}

img {
  vertical-align: middle;
  border: 0;
}

.title-left-title-font {
  float: left;
  margin: 0 10px;
  background: -webkit-linear-gradient(top, #fff, #65c4f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header {
  .el-dropdown {
    position: absolute;
    right: 25px;
    display: flex;
    align-items: center;

    .el-avatar {
      cursor: pointer;
      color: #409eff;
    }
    .el-dropdown-menu {
      font-size: 10px;
      cursor: pointer;
    }
  }
  .exit {
    position: absolute;
    right: 12px;
    color: #607d8b;
    font-size: 32px;
  }
}
</style>