<template>
  <div class="com-excel">
    <div class="tool-bar">
      <el-input
        v-if="!rowChange"
        placeholder="搜索"
        v-model="searchName"
        class="input-with-select"
      >
        <el-select
          v-model="selectValue"
          slot="prepend"
          placeholder="筛选类型"
          clearable
        >
          <el-option
            v-for="item in options"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-input>
      <el-button
        v-show="!rowChange"
        style="margin: 0px 20px 20px"
        class="binfo"
        type="primary"
        icon="el-icon-document"
        @click="exportExcel"
      >
        导出选择项
      </el-button>
      <el-button
        v-show="rowChange"
        style="margin: 0px 5px 20px"
        type="primary"
        icon="el-icon-document"
        @click="handleDownload"
      >
        导出选择项
      </el-button>
      <el-button
        v-show="rowChange && multipleSelection.length > 0"
        style="margin: 0px 5px 20px"
        type="danger"
        icon="el-icon-delete"
        @click="deleteSelect"
      >
        删除选择项
      </el-button>
    </div>

    <!-- <el-input v-model="searchName" placeholder="搜索" style="width: 200px" />
    <el-select v-model="selectValue" placeholder="请选择">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select> -->
    <!-- <el-input
      v-model="filename"
      :placeholder="'请输入导出文件名(默认' + defaultFilename + ')'"
      style="width: 350px; margin: 0 10px"
      prefix-icon="el-icon-document"
    /> -->
    <!-- //导出实时数据 -->

    <el-table
      ref="multipleTable"
      :data="tableList"
      v-loading="dataLoading"
      element-loading-text="拼命加载中"
      :row-class-name="rowChange ? tableRowClassName : ''"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40"> </el-table-column>
      <el-table-column type="index" width="50" label="#" align="center">
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columnProp"
        :key="index"
        :prop="item"
        :label="columnLabel[index]"
        :sortable="index == 2"
      >
      </el-table-column>
      <!-- <el-table-column :prop="columnProp[1]" label="子设备名称">
      </el-table-column>
      <el-table-column :prop="columnProp[2]" label="告警时间">
      </el-table-column> -->
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[30, 50, 100, tableListLength]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableListLength"
    >
    </el-pagination>
  </div>
</template>
<script>
import { delAlarmList } from "@/api/require";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import axios from "axios";
export default {
  props: {
    tableData: Array, //table数据
    columnProp: Array, //每一行显示数据
    columnLabel: Array, //每一行label名称
    defaultFilename: String, //默认导出文件名称
    rowChange: Boolean, //行颜色
  },
  data() {
    return {
      searchName: "", //搜索
      multipleSelection: [],
      filename: "",
      dataLoading: false, //表格加载
      selectValue: "", //过滤设备类型
      options: [
        //过滤设备类型
      ],
      currentPage: 1, //  el-pagination 初始页
      pagesize: 30, //  el-pagination 每页的数据
    };
  },
  created() {
    this.getOptionsType();
  },
  methods: {
    //表格选中
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //导出
    handleDownload() {
      if (this.multipleSelection.length) {
        import("@/vendor/Export2Excel").then((excel) => {
          const tHeader = [
            //表头
            // "设备名称",
            // "子设备名称",
            // "告警时间",
            // "描述",
            // "建议",
            ...this.columnLabel,
          ];
          const filterVal = [
            //表数据
            // "Devname",
            // "Sub_name",
            // "AlarmTime",
            // "AlarmDesc",
            // "AlarmSuggest",
            ...this.columnProp,
          ];
          const list = this.multipleSelection;
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            //如果文件名没输入使用默认值
            filename:
              (this.filename ? this.filename : this.defaultFilename) +
              new Date().format("yyyy年MM月dd日 HH时mm分ss秒"),
          });
          //清除选中项
          this.$refs.multipleTable.clearSelection();
        });
      } else {
        this.$message({
          message: "请选择导出项",
          type: "warning",
        });
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    // 设备实时数据 新导出
    async exportExcel() {
      // 导出表格

      var wb = XLSX.utils.book_new(); //新建表格

      for (let a = 0; a < this.multipleSelection.length; a++) {
        let Temptable = [];
        var wbout, ws;
        Temptable.push({
          A: "子设备名称",
          B: "数据类型",
          C: "数据描述",
          D: "当前值",
        });
        for (let b = 0; b < this.multipleSelection[a].DevSlave.length; b++) {
          for (
            let c = 0;
            c < this.multipleSelection[a].DevSlave[b].length;
            c++
          ) {
            // console.log(this.multipleSelection[a].DevSlave[b][c]);
            const { data: res } = await axios.get("/API/GetDevData.php", {
              params: {
                DevID: this.multipleSelection[a].Index,
                DevIndex: this.multipleSelection[a].DevSlave[b][c].Index,
              },
            });
            res.Status.forEach((Data) => {
              var row = {
                A: this.multipleSelection[a].DevSlave[b][c].Desc,
                B: this.multipleSelection[a].DevSlave[b][c].Type,
                C: Data.Name,
                D: Data.AlarmDesc[Data.Value],
              };
              Temptable.push(row);
            });
            res.Data.forEach((Data) => {
              var row = {
                A: this.multipleSelection[a].DevSlave[b][c].Desc,
                B: this.multipleSelection[a].DevSlave[b][c].Type,
                C: Data.Name,
                D: Data.Value + Data.Unit,
              };
              Temptable.push(row);
            });
            res.Rated.forEach((Data) => {
              var row = {
                A: this.multipleSelection[a].DevSlave[b][c].Desc,
                B: this.multipleSelection[a].DevSlave[b][c].Type,
                C: Data.Name,
                D: Data.Value + Data.Unit,
              };
              Temptable.push(row);
            });
          }
        }
        ws = XLSX.utils.json_to_sheet(Temptable, {
          header: ["A", "B", "C", "D"],
          skipHeader: true,
        });
        //设置列宽
        ws["!cols"] = [
          {
            width: 15,
          },
          {
            width: 15,
          },
          {
            width: 15,
          },
          {
            width: 15,
          },
        ];
        try {
          XLSX.utils.book_append_sheet(wb, ws, this.multipleSelection[a].name); //新建Sheet
        } catch (e) {
          // console.log(e);
        }
        wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
      }

      // this.multipleSelection.forEach((devItem, devIndex) => {
      //   this.multipleSelection[devIndex].DevSlave.forEach(
      //     (typeItem, typeIndex) => {
      //       //遍历子设备类型
      //       this.multipleSelection[devIndex].DevSlave[typeIndex].forEach(
      //         async (slaveItem) => {
      //           const { data: res } = await axios.get("/API/GetDevData.php", {
      //             params: {
      //               DevID: devItem.Index,
      //               DevIndex: slaveItem.Index,
      //             },
      //           });
      //           console.log(res);
      //           res.Status.forEach((Data) => {
      //             var row = {
      //               A: slaveItem.Desc,
      //               B: slaveItem.Type,
      //               C: Data.Name,
      //               D: Data.AlarmDesc[Data.Value],
      //             };
      //             Temptable.push(row);
      //           });
      //           res.Data.forEach((Data) => {
      //             var row = {
      //               A: slaveItem.Desc,
      //               B: slaveItem.Type,
      //               C: Data.Name,
      //               D: Data.Value + Data.Unit,
      //             };
      //             Temptable.push(row);
      //           });
      //           res.Rated.forEach((Data) => {
      //             var row = {
      //               A: slaveItem.Desc,
      //               B: slaveItem.Type,
      //               C: Data.Name,
      //               D: Data.Value + Data.Unit,
      //             };
      //             Temptable.push(row);
      //           });

      //           ws = XLSX.utils.json_to_sheet(Temptable, {
      //             header: ["A", "B", "C", "D"],
      //             skipHeader: true,
      //           });
      //           // ws = XLSX.utils.json_to_sheet(Temptable);
      //           //设置列宽
      //           ws["!cols"] = [
      //             {
      //               width: 15,
      //             },
      //             {
      //               width: 15,
      //             },
      //             {
      //               width: 15,
      //             },
      //             {
      //               width: 15,
      //             },
      //           ];
      //           try {
      //             XLSX.utils.book_append_sheet(wb, ws, devItem.name); //新建Sheet
      //           } catch (e) {
      //             // console.log(e);
      //           }
      //           wbout = XLSX.write(wb, {
      //             bookType: "xlsx",
      //             bookSST: true,
      //             type: "array",
      //           });
      //         }
      //       );
      //     }
      //   );
      // });

      // setTimeout(() => {
      var timestamp = new Date().format("yyyy年MM月dd日hh小时mm分ss秒");
      try {
        saveAs(
          //Blob 对象表示一个不可变、原始数据的类文件对象。
          //Blob 表示的不一定是JavaScript原生格式的数据。
          //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
          //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
          new Blob([wbout], {
            type: "application/octet-stream",
          }),
          //设置导出文件名称
          (this.filename || this.defaultFilename) + timestamp + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      // console.log("wbout");
      return wbout;
      // }, 3000);
    },
    //删除所选历史告警
    deleteSelect() {
      const selectIndex = this.multipleSelection.map((el) => el.P_Index);
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await delAlarmList(selectIndex);
          if (res.code == 0) {
            this.$emit("update");
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "danger",
              message: res.result.Describe,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //获取设备类型
    async getOptionsType() {
      const { data: res } = await axios.get("/API/GetDevType.php");
      this.options = res;
    },
    // size-change	pageSize 改变时会触发	每页条数size
    // current-change	currentPage 改变时会触发	当前页currentPage
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    //table颜色
    tableRowClassName({
      row,
      // rowIndex
    }) {
      if (row.Status == 1) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
  },
  computed: {
    tableList() {
      return this.tableData
        .filter(
          (data) =>
            !this.searchName ||
            data.name.toLowerCase().includes(this.searchName.toLowerCase())
        )
        .filter(
          (data) =>
            !this.selectValue ||
            data.type.toLowerCase().includes(this.selectValue.toLowerCase())
        )
        .slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        ); //分页;
    },
    tableListLength() {
      return this.tableData
        .filter(
          (data) =>
            !this.searchName ||
            data.name.toLowerCase().includes(this.searchName.toLowerCase())
        )
        .filter(
          (data) =>
            !this.selectValue ||
            data.type.toLowerCase().includes(this.selectValue.toLowerCase())
        ).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.com-excel {
  width: 100%;
  height: 100%;
  // .el-input-group__prepend {
  //   width: 100px;
  // }
  .tool-bar {
    float: left;
  }
  .input-with-select {
    width: 340px;
  }
  .el-select .el-input {
    width: 130px;
  }
}
</style>