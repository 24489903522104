import axios from 'axios'

import VueCookie from "vue-cookies";

//开发模式
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = '/api';
  VueCookie.set('userid', 'oYN2s1Up1VS1dOrdfXRshU5OrYL0')
  // const userid = "oYN2s1Up1VS1dOrdfXRshU5OrYL0"
}
// VueCookie.set('userid', 'oYN2s1Up1VS1dOrdfXRshU5OrYL0')
const userid = VueCookie.get('userid')


// 设备概览
export function getHomeDev() {
  return axios.get('/API/GetHomeData.php', {
    params: {
      userid: userid
    }
  })
}
//设备列表
export function getDevList() {
  return axios.get("/API/DevList.php", {
    params: {
      userid: userid
    }
  })
}
// 设备列表实时信息
export function getDevData(DevID, DevIndex) {
  return axios.get("/API/GetDevData.php", {
    params: {
      DevID: DevID,
      DevIndex: DevIndex
    }
  })
}
//实时告警
export function getAlarmList() {
  return axios.get('/API/GetAlarmList.php', {
    params: {
      userid: userid
    }
  })
}
//群组管理
export function getGroupDev() {
  return axios.get('/API/GetGroupDev.php', {
    params: {
      userid: userid
    }
  })
}

//获取历史记录
export function getHisAlarmRecord(devIndex, subIndex, time, dataName) {
  return axios.get('/API/GetDevHisAlarm.php', {
    params: {
      userid: userid,
      Devindex: devIndex,
      SubIndex: subIndex,
      time: time,
      DataName: dataName
    }
  })
}
//获取设备子节点的数据列
export function getDataName(devIndex, subIndex) {
  return axios.get('/API/GetDevDataName.php', {
    params: {
      userid: userid,
      Devindex: devIndex,
      SubIndex: subIndex,
    }
  })
}
//获取历史记录
export function getDevHistoryData(devIndex, subIndex, startTime, stopTime, dataName) {
  return axios.post('/API/GetDevHisData.php', {
    params: {
      userid: userid,
      Devindex: devIndex,
      SubIndex: subIndex,
      StartTime: startTime,
      StopTime: stopTime,
      DataName: dataName
    }
  })
}
//获取历史告警
export function getHisAlarmData(devIndex, subIndex, startTime, stopTime) {
  return axios.get('/API/GetDevHisAlarm.php', {
    params: {
      userid: userid,
      Devindex: devIndex,
      SubIndex: subIndex,
      StartTime: startTime,
      StopTime: stopTime
    }
  })
}

//群组操作
export function groupDisp(type, name, devs, groupId) {
  return axios.post('/API/GroupDisp.php', {
    params: {
      userid: userid,
      type: type,
      name: name,
      devs: devs,
      GroupID: groupId
    }
  })
}

//删除历史告警
export function delAlarmList(list) {
  return axios.post('/API/DelAlarmList.php', {
    params: {
      IndexList: list
    }
  })
}

//登录
export function login(username, password) {
  return axios.get('/API/Login.php', {
    params: {
      phone: username,
      passwd: password
    }
  })
}

//设置账户
export function setUser(username, password) {
  return axios.get('/API/SetUser.php', {
    params: {
      userid: userid,
      phone: username,
      passwd: password
    }
  })
}
//获取用户数据
export function userData() {
  return axios.get('/API/GetUserData.php', {
    params: {
      userid: userid,
    }
  })
}

//获取设备设置
export function _getDevCtrl(type) {
  return axios.get('/API/GetDevCtrl.php', {
    params: {
      userid: userid,
      devType: type
    }
  })
}

//设备提交
export function _setDevCtrl(_devId, _devIndex, _ctrlIndex, _list) {
  return axios.get('/API/SetDevCtrl.php', {
    params: {
      userid: userid,
      devId: _devId,
      devIndex: _devIndex,
      ctrlIndex: _ctrlIndex,
      list: JSON.stringify(_list)
    }
  })
}


//获取设备设置升级列表
export function _getDevUpProgram() {
  return axios.get('/API/DevUpProgram.php', {
    params: {
      userid: userid,
    }
  })
}

//获取设备通知管理
export function _getNotification(type, devId, dType, slaveId) {
  return axios.get('/API/Notification.php', {
    params: {
      userid: userid,
      Type: type,
      DevType: dType,
      DevID: devId,
      SlaveId: slaveId
    }
  })
}

//获取设备通知管理
export function _setUserNotification(type, id, index, name, phone, email, smsActive, vmsActive, emailActive, smsList, vmsList, emailList, u_id) {
  return axios.get('/API/Notification.php', {
    params: {
      userid: userid,
      Id: id, //设备id
      DevType: index, //选中的设备类型
      Type: type, //操作类型
      Name: name,
      Phone: phone,
      Email: email,
      SmsActive: smsActive,
      VmsActive: vmsActive,
      EmailActive: emailActive,
      SmsSelect: smsList,
      VmsSelect: vmsList,
      EmailSelect: emailList,
      U_Id: u_id, //用户id
    }
  })
}
//删除通知用户对象
export function _deleteUserNotification(type, id) {
  return axios.get('/API/Notification.php', {
    params: {
      userid: userid,
      Type: type,
      U_Id: id
    }
  })
}